@tailwind base;
@tailwind components;
@tailwind utilities;

.rounded-sm:hover{
 background-color: #F5FFE8;
 border-top-right-radius: 20px;
 border-bottom-right-radius: 20px;
 color:#255906;
}
.highlight{
    background-color: #F5FFE8;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    color:#255906; 
}
.rounded-sm{
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

.user-img{
    background-size: cover!important;
    background-position: center!important;
    height: 45px;
    width: 45px;
    border-radius: 100px
}
.op-img{
    background-size: cover!important;
    background-position: center!important;
    height: 60px;
    width: 60px;
    border-radius: 10px;
}
.news-img{
    background-size: cover!important;
    background-position: center!important;
    height: 200px;
    width: 100%;
    border-radius: 10px;
}
.restbl td{
 padding: 20px;
}
.restbl th{
    padding: 20px;  
}
.css-19kzrtu {
    padding-left:0px!important;
    padding-right: 0px!important;
}


.card-input-element {
    display: none;
}
.analytics-card-input-element {
    display: none;
}

.card-input {
     box-shadow: 0 0 1px 1px #eee;
    margin: 10px;
    /* padding: 00px; */
}

.card-input:hover {
    cursor: pointer;
}

.card-input-element:checked + .card-input {
     /* box-shadow: 0 0 1px 1px #2ecc71; */
     background-color: #F5FFE8;
 }

 .analytics-card-input-element:checked + .card-input {
    /* box-shadow: 0 0 1px 1px #2ecc71; */
    border: 1px solid #50AB1A;
}